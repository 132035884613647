import Button from "antd/lib/button";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Table from "antd/lib/table";
import Typography from "antd/lib/typography";
import { navigate } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { selectTotal } from "../../state/cart";
import { formatCurrency } from "../../utils/helpers";

const { Paragraph } = Typography;

const columns = [
  { key: "title", dataIndex: "title" },
  { key: "price", dataIndex: "price", align: "right" },
];

const PaymentTotal = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const { total, subtotal, currency } = useSelector(selectTotal);

  const orderSummary = [
    {
      key: "merch",
      title: t("cart.subtotal"),
      price: formatCurrency(subtotal, currency, language),
    },
    {
      key: "total",
      title: t("cart.total"),
      price: formatCurrency(total, currency, language),
    },
  ];

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Table
        pagination={false}
        showHeader={false}
        columns={columns.map((column) => {
          const { key, dataIndex, title, align } = column;
          const renderTitle = t(title);
          return { key, dataIndex, title: renderTitle, align };
        })}
        dataSource={orderSummary}
        footer={() => (
          <>
            <Paragraph>{t("cart.footnote1")}</Paragraph>
            <Paragraph>{t("cart.footnote2")}</Paragraph>
          </>
        )}
      />
      <Row gutter={25} justify="end">
        <Col md={4}>
          <Button
            block
            type="primary"
            size="large"
            onClick={() => navigate("/app/checkout")}
          >
            {t("cart.toPayment")}
          </Button>
        </Col>
      </Row>
    </Space>
  );
};

export default PaymentTotal;
