import Button from "antd/lib/button";
import Col from "antd/lib/col";
import Empty from "antd/lib/empty";
import Row from "antd/lib/row";
import Space from "antd/lib/space";
import Typography from "antd/lib/typography";
import { navigate } from "gatsby";
import { groupBy, map } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { selectItems } from "../../state/cart";
import CartTable from "./CartTable";
import PaymentTotal from "./PaymentTotal";

const { Title } = Typography;

const Cart = () => {
  const isMobile = useMediaQuery({ maxWidth: 1224 });

  const { t } = useTranslation();
  const cartItems = useSelector(selectItems);
  const cartItemsByPlatform = groupBy(cartItems, "platform");

  return (
    <Row justify="center">
      <Col span={22} sm={22} md={24}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Title
            level={isMobile ? 3 : 1}
            style={isMobile && { marginTop: 20, marginBottom: 0 }}
          >
            {t("cart.cart")}
          </Title>
          {cartItems.length > 0 ? (
            <>
              {map(cartItemsByPlatform, (items, platform) => (
                <CartTable
                  key={platform}
                  data={items}
                  platform={platform}
                  label={`${platform} ${t("cart.platform")}`}
                />
              ))}
              {/* <PaymentTotal /> */}
            </>
          ) : (
            <Empty description={t("saved.noItems")}>
              <Button onClick={() => navigate("/app/search")}>
                {t("saved.allProducts")}
              </Button>
            </Empty>
          )}
        </Space>
      </Col>
    </Row>
  );
};

export default Cart;
